function cloudSvg() {
  return (
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 88">
      <path d="M800 5.01V88H0V5c36.36 0 68.49 18.14 87.82 45.87C107.22 31.2 134.19 19 164 19c23.45 0 45.15 7.55 62.79 20.36C246.41 15.35 276.39 0 310 0c25.63 0 49.16 8.93 67.59 23.83C396.2 12.52 418.08 6 441.5 6c35.06 0 66.69 14.61 89.01 38.04C548.82 20.87 577.17 6 609 6c41.76 0 77.54 25.6 92.5 61.96C718.37 30.83 755.9 5 799.5 5c.17 0 .33 0 .5.01Z" />
    </svg>
  );
}

function getDefaultAvatar(displayName: string | null): string {
  if (!displayName) {
    return "/images/avatars/default-1.svg";
  }

  // Get the first character and convert to lowercase for consistency
  const firstChar = displayName.charAt(0).toLowerCase();

  // Convert character to a number 0-25 (if it's a letter)
  const charCode = firstChar.charCodeAt(0) - "a".charCodeAt(0);

  // If it's not a letter (charCode < 0 or > 25), or empty string, use default-1
  if (charCode < 0 || charCode > 25 || !displayName) {
    return "/images/avatars/default-1.svg";
  }

  // Deterministically choose between 1, 2, or 3 based on the character code
  const avatarNumber = (charCode % 3) + 1;
  return `/images/avatars/default-${avatarNumber}.svg`;
}

type Props = {
  url: string | null;
  displayName: string | null;
  cloudWrapperClassName?: string;
  isCloudShown?: boolean;
};

export default function ProfilePicture({ url, displayName, cloudWrapperClassName = "", isCloudShown = false }: Props) {
  const renderProfilePicture = () => {
    const defaultAvatarUrl = getDefaultAvatar(displayName);

    if (url) {
      return (
        <img
          src={url}
          alt={`${displayName}'s Profile Picture`}
          className="w-full h-full object-contain"
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.onerror = null; // Prevent infinite loop
            target.src = defaultAvatarUrl;
            target.className = "w-full h-full object-cover object-top scale-110 bg-base-200"; // Match default avatar styling
          }}
        />
      );
    }

    return (
      <img
        src={defaultAvatarUrl}
        alt={`${displayName}'s Profile Picture`}
        className="w-full h-full object-cover object-top scale-110 bg-base-200"
      />
    );
  };

  return (
    <div className="relative w-full h-full">
      {renderProfilePicture()}
      {isCloudShown && (
        <div className={`absolute left-10 text-base-100 w-full scale-[2.5] origin-bottom ${cloudWrapperClassName}`}>
          {cloudSvg()}
        </div>
      )}
    </div>
  );
}
