import { BellIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { usePageContext } from "vike-react/usePageContext";

import { Link } from "@/components/Link";
import { useUser } from "@/hooks/useUser";
import { useWebSocketConsumer } from "@/hooks/useWebSocketConsumer";

export default function NotificationsLink({ onClick }: { onClick?: () => void }) {
  const pageContext = usePageContext();
  const consumer = useWebSocketConsumer();
  const { user, setUser } = useUser();

  useEffect(() => {
    if (!consumer) return;

    const subscription = consumer.subscriptions.create(
      {
        channel: "Noticed::NotificationChannel",
      },
      {
        received(data: { unread_notifications_count: number }) {
          if (user) {
            setUser({
              ...user,
              unread_notifications_count: data.unread_notifications_count,
            });
          }
        },
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [consumer, user, setUser]);

  const renderUnreadNotificationsCount = () => {
    if (!user) return null;

    return (
      user.unread_notifications_count > 0 && (
        <div className="absolute -top-2 -right-2 bg-primary rounded-full w-4 h-4 flex items-center justify-center">
          <span className="text-base-100 text-[0.6rem] font-medium">{user.unread_notifications_count}</span>
        </div>
      )
    );
  };

  return (
    <Link
      to="/notifications"
      onClick={onClick}
      className={`flex items-center gap-2 focus:bg-primary/5 hover:bg-primary/5 active:bg-primary/5 ${pageContext.urlPathname.startsWith("/notifications") ? "bg-primary/5" : ""}`}
    >
      <div className="relative">
        <BellIcon className="h-5 w-5" />
        {renderUnreadNotificationsCount()}
      </div>
      <span>Notifications</span>
    </Link>
  );
}
