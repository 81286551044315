import { createConsumer } from "@rails/actioncable";
import { useEffect, useState } from "react";

export function useWebSocketConsumer() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [consumer, setConsumer] = useState<any>(null);

  useEffect(() => {
    const newConsumer = createConsumer(`${import.meta.env.VITE_API_BASE_URL}/cable`);
    setConsumer(newConsumer);

    return () => {
      if (consumer) {
        consumer.disconnect();
        setConsumer(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return consumer;
}
