import { Bars3BottomLeftIcon, BellIcon } from "@heroicons/react/24/solid";

import { Link } from "@/components/Link";
import Logo from "@/components/Logo";

export default function TopNav() {
  return (
    <div className="navbar bg-primary text-primary-content lg:hidden z-50 sticky top-0">
      <div className="navbar-start">
        <div className="dropdown">
          <label htmlFor="layout-drawer" role="button" className="btn btn-ghost btn-circle">
            <Bars3BottomLeftIcon className="h-5 w-5" />
          </label>
        </div>
      </div>
      <div className="navbar-center text-primary-content">
        <Logo />
      </div>
      <div className="navbar-end">
        <Link to="/notifications" className="btn btn-ghost btn-circle">
          <div className="indicator">
            <BellIcon className="h-5 w-5" />
          </div>
        </Link>
      </div>
    </div>
  );
}
