import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import { useChord } from "@/hooks/useChord";
import { usePageContext } from "vike-react/usePageContext";
import {
  BuildingStorefrontIcon,
  CloudIcon,
  Cog6ToothIcon,
  GiftIcon,
  HomeIcon,
  ShoppingBagIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

import { Link } from "@/components/Link";
import Logo from "@/components/Logo";
import MessagesLink from "@/layouts/dashboard/MessagesLink";
import NotificationsLink from "@/layouts/dashboard/NotificationsLink";
import ProfilePicture from "@/components/ProfilePicture";

import type { CurrentUser } from "@/types/user";

export default function DrawerContent({ user, closeDrawer }: { user?: CurrentUser; closeDrawer: () => void }) {
  const pageContext = usePageContext();
  const chord = useChord();
  const [theme, setTheme] = useState("dreamer");

  useEffect(() => {
    // Get initial theme from localStorage or default to "dreamer"
    const savedTheme = localStorage.getItem("theme") || "dreamer";
    setTheme(savedTheme);
    document.documentElement.setAttribute("data-theme", savedTheme);
  }, []);

  const handleThemeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newTheme = e.target.value;
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
    document.documentElement.setAttribute("data-theme", newTheme);
  };

  const handleNavigationLinkClick = (url: string, title: string) => {
    chord.trackNavigationClicked({
      navigationUrl: url,
      navigationTitle: title,
      label: title,
    });
    closeDrawer();
  };

  return (
    <div className="drawer-side z-50">
      <label htmlFor="layout-drawer" aria-label="close sidebar" className="drawer-overlay"></label>
      <ul className="menu bg-base-100 text-base-content w-80 min-h-full p-4 fixed [&>li]:py-1 border-r border-base-200">
        {/* Sidebar content here */}
        <header className="h-[46px] px-8 mt-4 mb-2 flex items-center justify-center text-primary">
          <Logo />
        </header>
        <li>
          <Link
            to="/feed"
            onClick={() => handleNavigationLinkClick("/feed", "Feed")}
            className={`flex items-center gap-2 focus:bg-primary/5 hover:bg-primary/5 active:bg-primary/5 ${pageContext.urlPathname.startsWith("/feed") ? "bg-primary/5" : ""}`}
          >
            <HomeIcon className="h-5 w-5" />
            <span>Feed</span>
          </Link>
        </li>
        <li>
          <Link
            to="/dreamers"
            onClick={() => handleNavigationLinkClick("/dreamers", "Dreamers")}
            className={`flex items-center gap-2 focus:bg-primary/5 hover:bg-primary/5 active:bg-primary/5 ${
              pageContext.urlPathname.startsWith("/dreamers") ? "bg-primary/5" : ""
            }`}
          >
            <CloudIcon className="h-5 w-5" />
            <span>Dreamers</span>
          </Link>
        </li>
        <li>
          <Link
            to="/market"
            onClick={() => handleNavigationLinkClick("/market", "Market")}
            className={`flex items-center gap-2 focus:bg-primary/5 hover:bg-primary/5 active:bg-primary/5 ${
              pageContext.urlPathname.startsWith("/market") || pageContext.urlPathname.startsWith("/brands")
                ? "bg-primary/5"
                : ""
            }`}
          >
            <BuildingStorefrontIcon className="h-5 w-5" />
            <span>Market</span>
          </Link>
        </li>
        <li>
          <Link
            to="/wishlists"
            onClick={() => handleNavigationLinkClick("/wishlists", "Wishlists")}
            className={`flex items-center gap-2 focus:bg-primary/5 hover:bg-primary/5 active:bg-primary/5 ${
              pageContext.urlPathname.startsWith("/wishlists") ? "bg-primary/5" : ""
            }`}
          >
            <GiftIcon className="h-5 w-5" />
            <span>Wishlists</span>
          </Link>
        </li>
        <li>
          <Link
            to="/orders"
            onClick={() => handleNavigationLinkClick("/orders", "Orders")}
            className={`flex items-center gap-2 focus:bg-primary/5 hover:bg-primary/5 active:bg-primary/5 ${pageContext.urlPathname.startsWith("/orders") ? "bg-primary/5" : ""}`}
          >
            <ShoppingBagIcon className="h-5 w-5" />
            <span>Orders</span>
          </Link>
        </li>
        <li>
          <MessagesLink onClick={() => handleNavigationLinkClick("/chat", "Chat")} />
        </li>
        <li>
          <NotificationsLink onClick={() => handleNavigationLinkClick("/notifications", "Notifications")} />
        </li>
        <li>
          <Link
            to="/account/profile"
            onClick={() => handleNavigationLinkClick("/account/profile", "Account")}
            className={`flex items-center gap-2 focus:bg-primary/5 hover:bg-primary/5 active:bg-primary/5 ${pageContext.urlPathname.startsWith("/account") ? "bg-primary/5" : ""}`}
          >
            <Cog6ToothIcon className="h-5 w-5" />
            <span>Account</span>
          </Link>
        </li>

        {/* User profile */}
        <div className="mt-auto border-t border-base-200">
          <div className="px-2 my-4 flex items-center justify-between">
            <label className="text-xs font-medium text-base-content/70">Theme</label>
            <select className="select select-xs select-ghost w-full ml-4" value={theme} onChange={handleThemeChange}>
              <option value="dreamer">☀️ Dreamer Light</option>
              <option value="dark">🌙 Dreamer Dark</option>
              <option value="cupcake">🧁 Cupcake</option>
            </select>
          </div>

          <div className="flex items-center gap-3 px-2 pt-4 border-t border-base-200">
            <Link
              to={`/${user?.slug || user?.id}`}
              onClick={() =>
                chord.trackNavigationClicked({
                  navigationUrl: `/${user?.slug || user?.id}`,
                  navigationTitle: "Profile",
                  label: "Profile",
                })
              }
            >
              <div className="avatar">
                <div className="w-12 h-12 rounded-2xl">
                  <ProfilePicture url={user?.profile_picture_url || null} displayName={user?.display_name || null} />
                </div>
              </div>
            </Link>
            <div className="flex flex-col">
              <div className="flex items-center gap-1">
                <span className="font-medium">{user?.display_name || "Dreamer"}</span>
                {user?.verified && <CheckBadgeIcon className="w-4 h-4 text-primary" />}
              </div>
              <span className="text-sm text-base-content/70">{user?.followers_count} followers</span>
            </div>
          </div>
        </div>
      </ul>
    </div>
  );
}
